import React from "react";
import { Box, Flex, Text, useTheme } from "@100mslive/roomkit-react";
//import { CREATE_ROOM_DOC_URL } from "../common/constants";
import JoinForm from "./JoinForm";
function ErrorPage({ error }) {
  const themeType = useTheme().themeType;
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        size: "100%",
        color: "$on_surface_high",
        backgroundColor: "$background_default",
      }}
    >
      <Box css={{ position: "relative", overflow: "hidden", r: "$3" }}>
        <img
          src={
            themeType === "dark"
              ? require("../images/error-bg-dark.svg")
              : require("../images/error-bg-light.svg")
          }
          alt="Error Background"
        />
        {window.location.hostname === "localhost" ? (
          <Flex
            align="center"
            direction="column"
            css={{ position: "absolute", size: "100%", top: "33.33%", left: 0 }}
          >
            <Text className="text-white text-xl">Welcome to the CONCACAF Ordinary Congress!</Text>
            <JoinForm />
          </Flex>
        ) : (
          <Flex
            align="center"
            direction="column"
            css={{ position: "absolute", size: "100%", top: "33.33%", left: 0 }}
          >
            <Text className="text-white text-xl">Welcome to the CONCACAF Ordinary Congress!</Text>
            <JoinForm />
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

ErrorPage.displayName = "ErrorPage";

export default ErrorPage;
