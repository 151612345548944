// @ts-check
import { useCallback } from "react";
import {
  selectPeerNameByID,
  selectSessionMetadata,
  useHMSActions,
  useHMSStore,
  useHMSVanillaStore,
} from "@100mslive/react-sdk";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_STORE_KEY } from "../../common/constants";

/**
 * set pinned chat message by updating the session store
 */
export const useSetPinnedMessage = () => {
  const hmsActions = useHMSActions();
  const vanillaStore = useHMSVanillaStore();
  const pinnedMessage = useHMSStore(selectSessionMetadata);

  const setPinnedMessage = useCallback(
    /**
     * @param {import("@100mslive/react-sdk").HMSMessage | undefined} message
     */
    async message => {
      const peerName = vanillaStore.getState(
        selectPeerNameByID(message?.sender)
      );
      const senderName = message?.senderName;
      let newPinnedMessage = null;
      if (message) {
        if (peerName) {
          newPinnedMessage = `${peerName}: ${message.message}`;
        } else if (senderName) {
          newPinnedMessage = `${senderName}: ${message.message}`;
        } else {
          newPinnedMessage = message.message;
        }
      }

      if (newPinnedMessage !== pinnedMessage) {
        await hmsActions.sessionStore
          .set(SESSION_STORE_KEY.PINNED_MESSAGE, newPinnedMessage)
          .catch(err => ToastManager.addToast({ title: err.description }));
      }
    },
    [hmsActions, vanillaStore, pinnedMessage]
  );

  return { setPinnedMessage };
};
