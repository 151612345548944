import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function JoinForm() {
  const [formValues, setFormValues] = useState({
    roomCode: "",
  });
  const navigate = useNavigate();
  const inputFileRef = useRef();

  const handleChange = event => {
    const { name, value } = event.target;
    console.log(name, value);

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formValues);
    console.log(inputFileRef?.current?.files);
    //setIsFormVisible(false);
    navigate(`/meeting/${formValues.roomCode}`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Join Room</h2>
      {/* 
      <div className="input-container">
        <input
          required
          value={inputValues.name}
          onChange={handleInputChange}
          id="name"
          type="text"
          name="name"
          placeholder="Your name"
        />
      </div>
      */}
      <div className="input-container">
        <input
          id="room-code"
          type="text"
          name="roomCode"
          placeholder="Room code"
          onChange={handleChange}
        />
      </div>
      <button type="submit" className="btn-primary">
        Join
      </button>
    </form>
  );
}

export default JoinForm;
